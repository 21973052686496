// This function allows animated transitions between hidden and displayed elements
window.animateDisplayTransition = (displayedElementId, hiddenElementId, callback) => {
  if (displayedElementId) {
    hideElement(displayedElementId);
  }
  if (hiddenElementId) {
    showElement(hiddenElementId);
  }

  if (typeof (callback) === "function") {
    callback();
  }
}

window.showElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element || !element.hidden) {
    return;
  }

  element.style.opacity = "0";
  element.hidden = false;

  setTimeout(() => {
    element.style.opacity = "100";
  }, 20);
}

window.hideElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element || !element.hidden) {
    element.hidden = true;
  }
}
