// Load polyfills
import "core-js/actual/typed-array"; // Used by @aws-sdk/util-utf8-browser, along with some of its dependencies
import "core-js/actual/url";
import "current-script-polyfill";
import "../src/polyfills/custom_event_pollyfill";
import "../src/polyfills/promise_polyfill";

// General libraries
import Rails from "@rails/ujs";

// Utilities
import "../src/animate";
import "../src/dispatch_dbfp_event";
import "../src/dbfp_updated";

// Stylesheets
import "stylesheets/application.scss";

// Images
require.context('images', true)

Rails.start();
