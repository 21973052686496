$(() => {
  // The DBFP script generates an event when the cookie with the user's
  // data is ready. This is to guard against some sort of unexpected failure:
  // the bfp.js script never loads, the Ajax call to the collector fails, etc
  // NOTE: This timeout will always fire, and sometimes the DBFP script emits
  // this event more than once. Any event listener should be prepared to handle
  // this gracefully.
  const bfpTimeout = 6500;
  const loadEvent = new CustomEvent('onBfpDataLoad');
  setTimeout(() => document.dispatchEvent(loadEvent), bfpTimeout);
});
